import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li className="active">
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/woordenlijst/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/lexique/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="189efbb909640496a9cd528b9ac4910243adfff7"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/fr/lexique/"> Lexique</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Comment agit IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Comment agit IMODIUM®?"
                  />
                </div>
                <p>
                  Cette vidéo montre comment Imodium<sup>®</sup>&nbsp;aide à
                  restaurer l‘équilibre naturel de vos intestins.
                </p>
                <a href="/fr/comment-agit-imodium/">Plus d'info</a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Conseils en cas de diarrhée</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Conseils en cas de diarrhée"
                  />
                </div>
                <p>
                  Quand il s'agit du traitement des symptômes de la diarrhée, il
                  existe encore de nombreuses incertitudes. Lire quelques
                  conseils.
                </p>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils diarrhée
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-41">
            <h1>Lexique</h1>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/abdominal/">Abdominal</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Relatif à l'abdomen. Communément appelé le ventre, c'est la
                  partie du corps comprise entre la poitrine et le bassin.
                  L'abdomen contient des organes tels que l'estomac, l'intestin
                  grêle, le côlon, le rectum, et la vessie.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/diarrhee-aigue/">Diarrhée aiguë</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Une diarrhée aiguë est une crise soudaine de diarrhée qui dure
                  moins de deux semaines.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/allergie/">Allergie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Une réaction du système immunitaire de l'organisme vis-à-vis
                  de substances étrangères. Les substances qui déclenchent une
                  allergie sont appelés allergènes, comme par ex. les pollens,
                  les acariens et certains aliments.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/antibiotiques/">Antibiotiques</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Médicaments utilisés pour traiter les infections provoquées
                  par des bactéries nuisibles et par d'autres micro-organismes.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/antispasmodique/">Antispasmodique</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Un médicament qui atténue ou empêche les spasmes musculaires.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/bacteries/">Bactéries</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Également appelés familièrement microbes, ces micro-organismes
                  unicellulaires sont souvent liés aux infections mais certains
                  d'entre eux sont inoffensifs et/ou bénéfiques pour l'homme.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/systeme-nerveux-central/">
                    Système nerveux central
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Le système nerveux central (SNC) est la partie du système
                  nerveux formée par le cerveau et la moelle épinière.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/diarrhee-chronique/">
                    Diarrhée chronique
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  L'expression "diarrhée chronique" est employée pour décrire
                  des symptômes qui durent plus de quatre semaines.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/constipation/">Constipation</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Défécations peu fréquentes et souvent incomplètes ; le
                  contraire de la diarrhée.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/intestins/">Intestins</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Partie du système digestif, constitué de l'intestin grêle et
                  du gros intestin, se trouvant dans l'abdomen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/deshydratation/">Déshydratation</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Survient lorsque l'organisme perd une quantité excessive
                  d'eau. Des vomissements prolongés ou une diarrhée peuvent
                  mener à la déshydratation, tout comme l'exposition à la
                  chaleur, l'exercice physique intensif prolongé, et certains
                  médicaments.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/diarrhee/">Diarrhée</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Selles anormalement molles ou liquides, émises lors d'accès
                  survenant plus de trois fois par jour, et/ou selles en
                  quantité supérieure à 200 g sur une journée.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/flatulence/">Flatulence</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Excès de gaz dans les intestins. Lâcher des gaz environ 14
                  fois sur 24 heures est considéré la "norme".
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/gastro-enterite/">Gastro-entérite</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Une inflammation de l'estomac et des intestins, qui peut
                  occasionner nausées, vomissements et/ou diarrhée. Des virus,
                  une intoxication alimentaire et le stress peuvent provoquer
                  une gastro-entérite.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/systeme-immunitaire/">
                    Système immunitaire
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Le système de défense naturel de notre organisme. Il identifie
                  les substances étrangères et nous protège contre les
                  infections.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/laxatifs/">Laxatifs</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Produits chimiques ou médicaments qui relâchent les intestins
                  pour combattre la constipation; peuvent provoquer de la
                  diarrhée si on en abuse.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/loperamide/">Lopéramide</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Un médicament utilisé dans les traitements antidiarrhéiques,
                  qui ralentit la contraction des muscles dans les intestins.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/menstruation/">Menstruation</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Le sang des règles qui est évacué de l'utérus,
                  approximativement toutes les 4 semaines, celles-ci constituant
                  le cycle menstruel.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/nausee/">Nausée</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Une forte envie de vomir, qui peut avoir de nombreuses causes,
                  dont la maladie, les médicaments et un déséquilibre dans
                  l'oreille interne.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/norovirus/">Norovirus</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Il existe en fait de nombreux types de norovirus ; ensemble,
                  ils font partie d'un groupe qui constitue l'origine la plus
                  fréquente des gastroentérites. Bien qu'ils puissent infecter
                  l'homme à tout moment de l'année, ils sont souvent appelés la
                  "maladie des vomissements d'hiver" car ils apparaissent plus
                  souvent en hiver.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/nutriments/">Nutriments</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Substances provenant de la nourriture et dont l'organisme a
                  besoin pour son énergie, sa croissance et son rétablissement,
                  comme par exemple vitamines, minéraux, glucides, graisses, et
                  protéines.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/ballonnements/">Ballonnements</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Un ballonnement est souvent associé à l'abdomen, lorsque
                  celui-ci est rempli et tendu sous l'effet des gaz intestinaux.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/peristaltisme/">Péristaltisme</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Le mouvement généré par des muscles de nos intestins qui se
                  contractent et se relâchent en vagues régulières, propulsant
                  le contenu tout le long des intestins. Ce mouvement mélange
                  également la nourriture avec les acides gastriques, la
                  transformant en un liquide dilué.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/rehydratation/">Réhydratation</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Le processus rendant au corps l'eau perdue, que ce soit en
                  buvant du liquide ou par perfusion intraveineuse.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/sigmoidoscopie/">Sigmoïdoscopie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Une inspection du rectum et du côlon inférieur à l'aide d'un
                  fin tube éclairé appelé sigmoïdoscope, durant laquelle des
                  échantillons de tissus ou de cellules peuvent être collectés
                  pour examen ultérieur.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/simethicone/">Siméthicone</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Un médicament qui contribue à dissoudre les gaz provoquant la
                  flatulence.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/mucus/">Mucus</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Un fluide épais, visqueux, produit par les membranes qui
                  tapissent certains organes comme le nez, la bouche, la gorge
                  et le vagin.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/selles/">Selles</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Le déchet solide qui passe par le rectum durant le transit
                  intestinal. Les selles sont constituées de nourriture non
                  digérée, de bactéries, de mucus, et de cellules mortes.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/fr/lexique/symptome/">Symptôme</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Un signe physique ou mental dénotant un état ou une maladie
                  d'une personne. La diarrhée, le mal de tête, la fièvre, la
                  fatigue, la nausée, la douleur et la dépression sont tous des
                  symptômes courants.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
